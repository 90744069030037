import * as React from 'react'
import { auth0Roles, strapiURL } from '../config'
import axios from 'axios'
import cookies from 'react-cookies'
import { navigate } from 'gatsby'
import { useDispatch } from 'react-redux'
import { setProducts } from '../redux/actions/user'
import { Spin } from 'antd'
import { paramsReturnHandler } from '../data'
import useGMT from '../hooks/useGTM'

const SecondPage = ({ location }) => {
  let _user = cookies.load('user')
  const { success, session_id, first_registration } = paramsReturnHandler(
    location.search
  )
  const { pushCandidatePurchaseEvent, pushEmployerPurchaseEvent } = useGMT()
  if (success) {
    axios
      .get(strapiURL + `/stripe/session/checkout/${session_id}`)
      .then(res => {
        typeof window !== 'undefined' &&
          window.gtag('event', 'purchase', res.data)
      })
      .catch(err => {
        console.log(err)
      })
    axios
      .post(strapiURL + '/carts/updateUserCart', {
        user: _user?.user?.id,
        cartData: {},
      })
      .then(response => {
        // console.log(response)
      })
      .catch(err => {
        // console.log(err)
      })
  }
  const dispatch = useDispatch()
  let user = cookies.load('user')
  React.useEffect(() => {
    if (user) {
      axios
        .post(strapiURL + '/check-user-subscriptions', {
          email: user?.username,
        })
        .then(res => {
          let newUser = {
            ...user,
            customer_id: res?.data?.customer_id,
            subscription_status: res?.data?.subscription_status,
          }
          cookies.save('user', newUser, { path: '/' })
        })
        .catch(err => {
          console.log(err)
        })
      axios
        .get(strapiURL + `/employers/${user?.user?.id}/subscriptionsAndCredits`)
        .then(res => {
          dispatch(setProducts(res?.data))
          if (
            user?.role?.id === auth0Roles?.Employer?.id ||
            user?.role?.id === auth0Roles?.NamedUser?.id
          ) {
            const empSubStr = localStorage.getItem('emp-subscription')
            if (empSubStr && session_id) {
              const empSubscription = JSON.parse(empSubStr)
              pushEmployerPurchaseEvent(
                session_id,
                empSubscription.subscription,
                empSubscription.addons
              )
              localStorage.removeItem('emp-subscription')
            }

            if (location?.search) {
              let _search = paramsReturnHandler(location.search)
              if (_search.employerRedirect === 'post') {
                navigate('/employer-dashboard/jobs/create')
              } else {
                navigate('/employer-dashboard/')
              }
            } else {
              navigate('/employer-dashboard/')
            }
          } else {
            const canSubStr = localStorage.getItem('can-subscription')
            if (canSubStr && session_id) {
              pushCandidatePurchaseEvent(canSubStr, session_id)
              localStorage.removeItem('can-subscription')
            }
            navigate(
              '/member-dashboard/',
              first_registration
                ? {
                    state: { firstRegistration: true },
                  }
                : undefined
            )
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [])

  return (
    <div>
      <h1 style={{ textAlign: 'center', paddingTop: '100px' }}>Please wait!</h1>
      <p style={{ textAlign: 'center' }}>
        Your subscription status is being updated.
      </p>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Spin size="large" />
      </div>
    </div>
  )
}

export default SecondPage
