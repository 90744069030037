export const setProducts = value => {
  return {
    type: 'SET_PRODUCTS',
    payload: value,
  }
}
export const logout = value => {
  return {
    type: 'LOGOUT',
    payload: value,
  }
}

export const setProfileData = value => {
  return {
    type: 'SET_PROFILE_DATA',
    payload: value,
  }
}

export const setNamedUserEmployerProfiles = value => {
  return {
    type: 'SET_NAMED_USER_EMPLOYER_PROFILES',
    payload: value,
  }
}
